import React, { memo, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer, Tooltip } from "react-leaflet";

import ReactDOMServer from "react-dom/server";
import "leaflet/dist/leaflet.css";

import datas from "./datas.json";
import { divIcon, DivIconOptions } from "leaflet";
import { MapIcon, StartIcon } from "./location-dot";

import cuisine from "./cuisine.json";

const InfoIcon = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            {...props}
        >
            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
        </svg>
    );
};

const Map: React.FC<{ children?: string }> = ({ children }) => {
    const [data, setData] = useState<{
        name: string;
        address: string;
        cuisine: string;
        rating: number;
        price_range: string;
        contact: string;
        latitude: number;
        longitude: number;
        img?: string;
        image?: string;
        feedbacks: {
            from: string;
            title: string;
            text: string;
        }[];
    } | null>(null);

    const [show, setShow] = useState(false);

    const createMarker = () => {
        const options: DivIconOptions = {
            className: "marker-wrapper",
            html: ReactDOMServer.renderToString(
                <div className="block">
                    <MapIcon className="w-10 h-10 fill-[#2c3e50] stroke-[#fff] stroke-[4]" />
                </div>
            ),
        };
        return divIcon(options);
    };

    return (
        <div style={{ position: "relative" }}>
            <div className="absolute bg-slate-50 py-3 px-6 rounded-br-2xl drop-shadow-xl z-[99999999999999] flex gap-3">
                <h1 className="font-medium text-xl text-zinc-600">
                    Карта для турристов
                </h1>

                <div onClick={() => setShow(true)} className="cursor-pointer">
                    <InfoIcon className="w-6 h-6 fill-zinc-600" />
                </div>
            </div>

            {show && (
                <div className="fixed top-0 bottom-0 left-0 right-0 px-6 py-4 flex flex-col gap-4 items-center bg-white z-[999999999999999999] overflow-y-auto">
                    <div className="w-full flex items-center gap-6">
                        <button
                            type="button"
                            className="font-medium text-zinc-800 text-lg bg-slate-200 hover:bg-slate-300 px-4 py-2 rounded-lg"
                            onClick={() => setShow(false)}
                        >
                            {"Закрыть"}
                        </button>

                        <h3 className="font-semibold text-2xl text-blue-600">
                            {"Французские блюда"}
                        </h3>
                    </div>

                    <ul className="flex flex-col gap-4">
                        {cuisine.map((val) => {
                            return (
                                <li className="flex gap-2 p-2 border rounded-lg border-gray-500 items-center">
                                    <img
                                        src={val.img}
                                        alt="eda"
                                        className="max-w-[20rem] object-fit rounded-lg w-full overflow-hidden"
                                    />

                                    <div className="flex flex-col gap-2">
                                        <p className="text-xl text-zinc-800 font-semibold">
                                            {val.name}
                                        </p>

                                        <p className="text-gray-500 ml-4">
                                            {val.desc}
                                        </p>
                                        <p className="text-gray-700">
                                            {val.ingredients}
                                        </p>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}

            {data && (
                <div className="fixed top-0 bottom-0 left-0 right-0 px-6 py-4 flex flex-col gap-4 items-center bg-white z-[999999999999999999] overflow-y-auto">
                    <div className="w-full flex items-center gap-6">
                        <button
                            type="button"
                            className="font-medium text-zinc-800 text-lg bg-slate-200 hover:bg-slate-300 px-4 py-2 rounded-lg"
                            onClick={() => setData(null)}
                        >
                            {"Закрыть"}
                        </button>

                        <div className="flex items-center gap-3">
                            <h3 className="font-semibold text-2xl text-blue-600">
                                {data.name}
                            </h3>

                            <p className="from-black text-xl">/</p>

                            <div className="flex items-center gap-1">
                                <p className="font-medium text-2xl text-orange-400">
                                    {data.rating}
                                </p>

                                <StartIcon className="w-4 h-4 fill-orange-400" />
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <img
                                src={data.image || data.img}
                                alt=""
                                className="max-h-[30rem] max-w-[60rem] mx-auto w-full object-cover rounded-lg"
                            />
                        </div>

                        <div className="flex flex-col gap-4">
                            <p className="text-xl text-zinc-700">
                                <span className="font-semibold">Адрес:</span>{" "}
                                {data.address}
                            </p>

                            <p className="text-xl text-zinc-700">
                                <span className="font-semibold">
                                    Номер доставки:
                                </span>{" "}
                                {data.contact}
                            </p>

                            <ul className="flex flex-col gap-2">
                                {data.feedbacks.map((feedback) => (
                                    <li className="flex flex-col gap-2 border border-gray-500 p-2 rounded">
                                        <p className="text-gray-500 ml-4">
                                            {feedback.title}
                                        </p>
                                        <p className="text-xl text-zinc-800 font-semibold">
                                            {feedback.from}
                                        </p>
                                        <p className="text-gray-700">
                                            {feedback.text}
                                        </p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}

            <MapContainer
                style={{ width: "100%", height: "100svh" }}
                zoom={14}
                center={[45.76342, 4.834277]}
                zoomControl={false}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    maxNativeZoom={19}
                    maxZoom={21}
                />

                {datas.map((marker) => (
                    <Marker
                        icon={createMarker()}
                        position={[marker.latitude, marker.longitude]}
                        eventHandlers={{
                            click() {
                                setData(marker);
                            },
                        }}
                    >
                        <Tooltip>
                            {marker.name} /{" "}
                            <span className="text-orange-500">
                                {marker.rating}
                            </span>
                        </Tooltip>
                    </Marker>
                ))}

                {children}
            </MapContainer>
        </div>
    );
};

export default memo(Map);
